import { graphql, PageProps } from "gatsby";
import { Link, Trans } from "gatsby-plugin-react-i18next";
import React from "react";
import { ArrowRight } from "react-feather";

import Logo from "../components/logo/Logo.component";
import { EmbedVideo } from "../components/videos/embed/EmbedVideo.component";
import { NativeVideo } from "../components/videos/native/NativeVideo.component";

import { ContentfulVideo } from "../shared/contentful/contentful.types";

import "./page-video.scss";

type Props = {
  pageData: {
    videoTitle: string | null;
    description: { description: string } | null;
    video: ContentfulVideo;
  };
};

const PageVideoTemplate = ({ data }: PageProps<Props>) => {
  if (!data) return null;

  const { pageData } = data;

  if (!pageData) return null;

  const { video, description, videoTitle } = pageData;
  if (!video) return null;

  const { videoUrl, asset, title } = video;

  const videoText = description?.description;

  return (
    <div className="page-video__wrapper">
      <Logo className={"page-video-logo"} />
      <div className="page-video__video-wrapper">
        {videoUrl && <EmbedVideo videoUrl={videoUrl} title={title} />}
        {asset && <NativeVideo asset={asset} />}
      </div>
      {(videoTitle || videoText) && (
        <div className="page-video__text-wrapper">
          {videoTitle && (
            <p className="page-video__video-title">{videoTitle}</p>
          )}
          {videoText && (
            <p className="page-video__video-description">{videoText}</p>
          )}
        </div>
      )}
      <Link to="/" className="page-video__cta-back-website">
        <span>
          <ArrowRight />
          <Trans>pageVideo__backToHome</Trans>
        </span>
      </Link>
    </div>
  );
};
export default PageVideoTemplate;

export const query = graphql`
  query singlePageVideo($language: String, $slug: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    pageData: contentfulPageVideo(
      pageSlug: { eq: $slug }
      node_locale: { eq: $language }
    ) {
      description {
        description
      }
      videoTitle
      video {
        videoUrl
        title
        asset {
          file {
            url
            fileName
            contentType
          }
        }
      }
    }
  }
`;
