import React from "react";

import { getIdFromVideoUrl } from "../../../shared/utils/video.utils";
import { VideoPlayerProps } from "../VideoPlayer.component";

import "./embed-video.scss";
import VimeoVideo from "./VimeoVideo.component";
import YoutubeVideo from "./YoutubeVideo.component";

export const EmbedVideo = ({
  videoUrl,
  title,
}: Omit<VideoPlayerProps, "closeVideo" | "asset">) => {
  if (!videoUrl) return null;

  const videoId = getIdFromVideoUrl(videoUrl);

  if (!videoId) return null;

  return (
    <>
      {videoUrl.includes("vimeo") ? (
        <VimeoVideo videoId={videoId} />
      ) : (
        <YoutubeVideo videoId={videoId} title={title} />
      )}
    </>
  );
};
