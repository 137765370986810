import React from "react";

type Props = {
  videoId: string;
};

const VimeoVideo = ({ videoId }: Props) => {
  return (
    <>
      <iframe
        className="vimeo-video__wrapper"
        width="560"
        height="315"
        src={`https://player.vimeo.com/video/${videoId}?color=ffffff&title=0&byline=0&portrait=0`}
        frameBorder="0"
        allow="autoplay; picture-in-picture; controls"
      />
      <script src="https://player.vimeo.com/api/player.js" />
    </>
  );
};
export default VimeoVideo;
