import React from "react";
import { VideoPlayerProps } from "../VideoPlayer.component";

export const NativeVideo = ({
  asset,
  title,
  description,
}: Omit<VideoPlayerProps, "closeVideo" | "videoUrl">) => (
  <>
    {asset && (
      <div className={"native-video__wrapper"}>
        {title && <p className={"video-title"}>{title}</p>}
        {asset && <video src={asset.file.url as any} controls />}
        {description && <p className={"video-description"}>{description}</p>}
      </div>
    )}
  </>
);
