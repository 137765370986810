import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

const Logo = ({ className }: { className: string }) => {
  const { t } = useTranslation();
  const { logo } = useStaticQuery(
    graphql`
      query logo {
        logo: contentfulAsset(contentful_id: { eq: "7yz4vgbhlW4V4v2BYgLW95" }) {
          title
          description
          file {
            url
          }
        }
      }
    `
  );
  return (
    <Link to={"/"} className={className}>
      <img
        src={logo.file.url}
        alt={`${t("homepage")}`}
        title={logo.file.title}
      />
    </Link>
  );
};
export default Logo;
