const getIdFromVimeo = (url: string): string | undefined => {
  const id = url.match(/\d+$/gm);
  return id?.[0] || undefined;
};

const getIdFromYoutube = (url: string): string | undefined => {
  const id = url.split("https://youtu.be/");
  return id?.[1] || undefined;
};

export const getIdFromVideoUrl = (url: string): string | undefined => {
  if (url.match(/vimeo/gm)) {
    return getIdFromVimeo(url);
  }
  if (url.includes("youtu.be")) {
    return getIdFromYoutube(url);
  }
};
