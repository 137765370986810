import React from "react";

type Props = {
  videoId: string;
  title?: string | null;
};

const YoutubeVideo = ({ videoId, title }: Props) => {
  return (
    <iframe
      className="youtube-video__wrapper"
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${videoId}`}
      title={title || ""}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    />
  );
};
export default YoutubeVideo;
